
import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import NavLinks from './components/NavLinks';
import ModList from './components/ModList';
import ServerConfigPlayfab from './components/ServerConfigPlayfab';
import "./App.css";
// import { DiscussionEmbed } from 'disqus-react';

function displayError(error) {
  return (
    <div className="ErrorBG font-starjedi">
      <h1>This is not the page you are looking for. </h1><h4>{error}</h4>
    </div>
  )
};

function displayNoSupport() {
  return (
    <div className="DefaultBG">
      <div className="notsupported">
        <h3>Sorry, not supported game type.</h3>
      </div>
    </div>
  )
};

function MhswServer(props) {
  // console.log(window.location.pathname.split('/')[2])
  const accountId = window.location.pathname.split('/')[2]
  // console.log("accountid: " + accountId)
  //const pathname = window.location.pathname
  //console.log(window.location.href)
  // const gametype=props.raw.folder
  //console.log(props)
  //const [serverconfig, setserverconfig] = useState({ "state": "loading" });
  const [serverconfig, setserverconfig] = useState(0);
  const [loadinganim, setloadinganim] = useState({ "loading": true });

  const fetchData = async () => {
    setloadinganim({ "loading": true })
    fetch('https://insapi.vinite.eu/api/mhswserveraccount/' + accountId)
      .then(response => response.json())
      .then(resultData => {
        setserverconfig(resultData);
        setloadinganim({ "loading": false })
      })
      .catch(error => setserverconfig({ "state": "error", "msg": error }))
  }

  // http://localhost:8010/proxy/api/104.243.35.239:27102
  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, [])

  // console.log(loadinganim.loading)
  //console.log(serverconfig)
  return (
    <div className="JediBG">
      <Container className="font-starjedi">
        <NavLinks />
        {serverconfig.status === "OK" ?
          <>
            <br />

            <ServerConfigPlayfab server={serverconfig.data.Games} loadinfo={loadinganim.loading} />
            {typeof serverconfig.Mods.data !== 'undefined' &&
              <ModList config={serverconfig.Mods} />
            }
            <Container className="brandtext">
              The JVS community MOD contributers (2022)
            </Container>
            {/* <DiscussionEmbed
              shortname='vinite'
              config={
                {
                  url: window.location.href,
                  identifier: serverconfig.data.Games[0].Tags.AccountID,
                  title: serverconfig.data.Games[0].Tags.ServerName,
                  language: 'en_EN'
                }
              }
            /> */}

          </>
          : serverconfig.state === "error" ? displayError(serverconfig.msg)
            : typeof serverconfig.status !== 'undefined' ? displayNoSupport()
              : <> <br />Loading...</>
        }
      </Container>
    </div>
  );
}


export default MhswServer;
