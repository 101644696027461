import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import "../App.css";
import { BsReceipt } from 'react-icons/bs';
import { RiCopyrightFill } from 'react-icons/ri';
import { BsPersonCircle } from 'react-icons/bs';
import { GiDeathSkull } from 'react-icons/gi';
import { GiImpactPoint } from 'react-icons/gi';
import { GiLevelEndFlag } from 'react-icons/gi';
import { FaGalacticSenate } from 'react-icons/fa';


export default function PlayerList(props) {
  // console.log("player:", props)
  return (
    <>
      <Container className="PlayerContainer">
        <Row className="playerRowHeader">
          <Col><BsPersonCircle /></Col>
          <Col className="d-none d-md-block"><GiLevelEndFlag /></Col>
          <Col className="d-none d-md-block"><BsReceipt className="grIcon" /></Col>
          <Col><GiImpactPoint /></Col>
          <Col className="d-none d-md-block"><GiDeathSkull /></Col>
        </Row>
        {props.config.Players.length > 0 ? props.config.Players.map((player, index) => (
          <Row key={index}>
            <Col className="playername">
              {player.score >= 0 && player.score < 2000 ? <FaGalacticSenate className="rank" /> :
                player.score >= 2000 && player.score < 4000 ? <FaGalacticSenate className="rank-green" /> :
                  player.score >= 4000 ? <FaGalacticSenate className="rank-purple" /> :
                    ''
              }
              {player.name}
              {player.name === "Spec" && <RiCopyrightFill className="copyright" />}
            </Col>
            <Col className="d-none d-md-block">{player.level}</Col>
            <Col className="d-none d-md-block">{player.score}</Col>
            <Col>{player.kills}</Col>
            <Col className="d-none d-md-block">{player.deaths}</Col>
          </Row>
        )) : <> No <b className="jedi">Jedi</b> or <b className="sith">Sith</b> or <b className="mando">Mandalorian</b> online </>}
      </Container>
    </>
  );
}