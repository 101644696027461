import React from 'react';
import { Container } from 'reactstrap';
import NavLinks from './components/NavLinks';
import "./App.css";

function App() {
  return (
    <>
      <div className="JediBG">
        <Container className="font-starjedi">
          <NavLinks />
          <br />
          <h2>Welcome Masters!</h2><br />

          <p>In honor of the Mordhau Star Wars Legends Mod and all contributers providing awsome looking maps, models, voices,
            this is my take on giving something back. https://mordhau.vinite.eu is server browser application dedicated to the Mod.
          </p>
          <ul>
            <li>
              It can help identify if servers are online.
            </li>
            <li>
              If there are players active on the server.
            </li>
            <li>
              What mods / maps are installed on a server.
            </li>
          </ul>
          <p>
            Through the use of modern tooling responsive design was key in development of this project.
            By doing so the app behaves nice on phones too.
          </p>
          <hr />
          <h5>Updates #2 02/2022</h5>
          <ul>
            <li>Update react packages.</li>
            <li>Remove unused components.</li>
          </ul>
          <hr />
          <h5>Updates 02/2022</h5>
          <ul>
            <li>Added additional map backgrounds.</li>
            <li>Removed reshade link.</li>
          </ul>
          <hr />
          <h5>Update list Januari 2022</h5>
          <ul>
            <li>Added community servers compact version.</li>
            <li>Minor UI changes.</li>
            <li>UI changes.</li>
            <li>Added new flags for server destinations.</li>
            <li>Added community server detail page.</li>
            <li>Added Mustafar, HothBattlefield and GeonosisArena backgrounds.</li>
            <li>Integrated personalized server with generic UI.</li>
            <li>Reordered Navigation links.</li>
            <li>Added react-world-flags module.</li>
            <li>Fixed element hierarchy issue in swservers.</li>
            <li>Dumped down the steam rungameid uri</li>
            <li>Update playfabapi filter to add Official JVS 3 team Deathmatch</li>
            <li>Start using the Playfabapi.</li>
            <li>Adding swservers page.</li>
            <li>Added react video component.</li>
            <li>Added demo video.</li>
            <li>Add reshade page and presets.</li>
            <li>Minor UI changes.</li>
            <li>UI changes and update backgrounds.</li>
            <li>Fix "index out of range" bug API backend.</li>
            <li>Minor UI changes.</li>
          </ul>
          <hr />
          <h5>updates December 2021</h5>
          <p>initial commit setting up the monitor framework for Mordhau.</p>
          <p>Unfortunatly I don't have access to the playfabAPI because I don't have a proper authorization token.
            Because of this I don't have dynamic access to a server playerlist and to the mods used by the server."</p>
          <p>To showcase What it should look like with the featured usage you can check my server which is currently
            using a mix of a2s and rcon stuff to get all the information required.</p>
          <a className="swlink" href="/starwars">Mordhau Star Wars server</a><br /><br />

          Regards,<br />
          Spec.<br />
        </Container>
      </div>
    </>
  );
}

export default App;
