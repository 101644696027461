import React from 'react';
import { Container } from 'reactstrap';
import "./Hell.css";

function Hell() {
    return (
        <div className="HellBG">
            <Container className="hellcontainer">
                <br />
                <b className="welcome">Welcome to Hell</b><br />
                <b className="rules">Rules:</b>
                <ul>
                    <li>Please remain calm.</li>
                    <li>Do not feed the demons.</li>
                    <li>Do not look demons directly in the eyes.</li>
                    <li>Only attack demons if they attack you first.</li>
                    <li>Make sure you have life insurance before entering the map.</li>
                    <li>Showing off shiny gear is allowed.</li>
                </ul>
                Have a good death, <br />
                <b className="satan">Satan</b>
                <div className="drop" />
                <div className="wave" />
            </Container>
        </div>
    )
}

export default Hell;