import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import "../App.css";
import { BsToggle2On } from 'react-icons/bs';
import { BsToggle2Off } from 'react-icons/bs';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import Flag from 'react-world-flags';

function getFlag(country) {
  switch (country) {
    case 'Germany': return <Flag code='de' height='16' />;
    case 'UK': return <Flag code='gb' height='16' />;
    case 'Brazil': return <Flag code='br' height='16' />;
    case 'US_West': return <Flag code='us' height='16' />;
    case 'US_East': return <Flag code='us' height='16' />;
    case 'US_Central': return <Flag code='us' height='16' />;
    case 'Australia': return <Flag code='au' height='16' />;
    case 'Singapore': return <Flag code='sg' height='16' />;
    case 'Russia': return <Flag code='ru' height='16' />;
    default: return <Flag code='unk' height='16' />;
  }
}

const override = css`
  text-align: right;
  display: block;
`;

// function getMap(map) {
//   switch (map) {
//     case 'JediTemple': return 'jedicard';
//     case 'Hoth': return 'HothBG';
//     default: return "SenateBG"
//   }
// }

export default function ServerConfigPlayfab(props) {
  //console.log(props)
  return (
    <>
      <Container className="ServerContainer">
        <Row>
          <Col xs="10">{getFlag(props.server[0].Tags.Location)} {props.server[0].Tags.ServerName}</Col>
          <Col xs="2">
            <ScaleLoader
              css={override}
              height={14}
              width={4}
              radius={3}
              margin={1}
              color={"#FF0000"}
              loading={props.loadinfo}
            />
          </Col>
        </Row>
        Players: {props.server[0].Tags.Players && props.server[0].Tags.Players.length !== 0 ? props.server[0].Tags.Players.split(',').length : 0} / {props.server[0].Tags.MaxPlayers}<br />
        Map: {props.server[0].Tags.MapName}<br />
        Mode: {props.server[0].Tags.GameMode}<br />
        Net: {props.server[0].ServerHostname}:{props.server[0].ServerPort}<br />
        BeaconPort: {props.server[0].Tags.BeaconPort}<br />
        Modded: {props.server[0].Tags.IsModded === 'true' ? <BsToggle2On /> : <BsToggle2Off />}<br />
        Official: {props.server[0].Tags.IsOfficial === 'true' ? <BsToggle2On /> : <BsToggle2Off />}<br />
        Password: {props.server[0].Tags.IsPasswordProtected === 'true' ? <BsToggle2On /> : <BsToggle2Off />}<br />
        OS: {props.server[0].Tags.OperatingSystem}<br />
        Server Version: {props.server[0].Tags.Version}<br />
        {/* Connect: <a href={getServerConnect(props.server[0].ServerHostname, props.server[0].ServerPort)}><BsFillPlayBtnFill size="30px" /></a><br /> */}

      </Container>
    </>
  )
}