import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import NavLinks from './components/NavLinks';
import ServerCard from './components/ServerCard';
import ServerCardCompact from './components/ServerCardCompact';
import "./App.css";

function displayError(error) {
  return (
    <div className="font-starjedi">
      <h1>This is not the page you are looking for. </h1><h4>{error}</h4>
    </div>
  )
};

function displayNoSupport() {
  return (
    <div className="DefaultBG">
      <div className="notsupported">
        <h3>Sorry, not supported game type.</h3>
      </div>
    </div>
  )
};

function SwServers(props) {
  const pathname = window.location.pathname.split('/')[1]
  //console.log(window.location.pathname.split('/')[2])
  //const serverip = window.location.pathname.split('/')[2]
  //const pathname = window.location.pathname
  // const gametype=props.raw.folder
  //console.log(pathname)
  //const [serverconfig, setserverconfig] = useState({ "state": "loading" });
  const [serverconfig, setserverconfig] = useState(0);
  const [loadinganim, setloadinganim] = useState({ "loading": true });

  const fetchData = async () => {
    setloadinganim({ "loading": true })
    fetch('https://insapi.vinite.eu/api/mhswservers', {
      headers: {
      }
    })
      .then(response => response.json())
      .then(resultData => {
        setserverconfig(resultData);
        setloadinganim({ "loading": false })
      })
      .catch(error => setserverconfig({ "state": "error", "msg": "Server info could not be retrieved." }))
  }

  // http://localhost:8010/proxy/api/104.243.35.239:27102
  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 120000);
    return () => clearInterval(interval);
  }, [])

  // console.log(loadinganim.loading)
  // console.log(serverconfig)
  return (
    <div className="JediBG">
      <Container className="font-starjedi">
        <NavLinks />
        {serverconfig.status === "OK" ?
          <>
            <br />
            {serverconfig.data.GameCount} Star Wars Servers online.

            {/* {serverconfig.data.Games.map((game, index) =>
            <>
              {game.LobbyID} <br />
              {game.Tags} <br />
            </>
          )} */}
            {pathname === "sws" ? <ServerCard servers={serverconfig.data.Games} loading={loadinganim} />
              : <ServerCardCompact servers={serverconfig.data.Games} loading={loadinganim} />
            }
          </>
          : serverconfig.state === "error" ? displayError(serverconfig.msg)
            : typeof serverconfig.status !== 'undefined' ? displayNoSupport()
              : <> <br />Loading...</>
        }
      </Container>
    </div >
  );
}


export default SwServers;
