import React from 'react';
import { Container } from 'reactstrap';
import "./App.css";
import NavLinks from './components/NavLinks';

function Reshade() {
    return (
        <div className="JediBG">
            <Container className="font-starjedi">
                <NavLinks />
                <br />
                <h2>Reshade</h2>
                <p>ReShade is a generic post-processing injector for games and video software developed by crosire.
                    Imagine your favorite game with ambient occlusion, real depth of field effects, color correction
                    and more ... ReShade exposes an automated and generic way to access both frame color and depth
                    information (latter is automatically disabled during multiplayer to prevent exploitation)
                    and all the tools to make it happen.
                </p>
                Source: <a href="https://reshade.me/" target="_new">https://reshade.me</a>
                <br /><br />
                <p>While practising to wield the Lightsaber I was also tinkering around with reshade
                    since I was using that in other games. I found some settings I would like to share.
                    This is by no means a tutorial for setting it all up, so if you need help reach out on
                    discord. Just make sure when configuring Reshade for Mordhau to install all shader packs.
                </p>
                <ul>
                    <li><a href="/Mordhau_SW_Hoth.ini" target="_self">Mordhau_SW_Hoth.ini</a></li>
                    <li><a href="/Mordhau_SW_JediTemple.ini" target="_self">Mordhau_SW_JediTemple.ini</a></li>
                </ul>
                <br />
                Have fun, Spec.

            </Container>
        </div>
    )
}

export default Reshade;