import React from 'react';
import { Col } from 'reactstrap';
import "../App.css";
import { CardGroup, Card, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap'
import Flag from 'react-world-flags'
import { BsToggle2Off } from 'react-icons/bs'
import { BsToggle2On } from 'react-icons/bs'
import { BsFillPlayBtnFill } from 'react-icons/bs'

function getCardBG(map) {
    switch (map) {
        case 'JediTemple': return 'jedicard';
        case 'Hoth': return 'hothcard';
        case 'HothBattlefield': return 'hothbattlefieldcard';
        case 'Mustafar': return 'mustafarcard';
        case 'GeonosisArena': return 'geonosiscard';
        case 'Bespin': return 'bespincard';
        case 'Tatooine': return 'tatooinecard';
        case 'ImperialCruiser': return 'imperialcruisercard';
        case 'StarKillerForest': return 'starkillerforestcard';
        default: return "defaultcard"
    }
}

function getFlag(country) {
    switch (country) {
        case 'Germany': return <Flag code='de' height='16' />;
        case 'UK': return <Flag code='gb' height='16' />;
        case 'Brazil': return <Flag code='br' height='16' />;
        case 'US_West': return <Flag code='us' height='16' />;
        case 'US_East': return <Flag code='us' height='16' />;
        case 'US_Central': return <Flag code='us' height='16' />;
        case 'Australia': return <Flag code='au' height='16' />;
        case 'Singapore': return <Flag code='sg' height='16' />;
        case 'Russia': return <Flag code='ru' height='16' />;
        default: return <Flag code='unk' height='16' />;
    }
}

function getSteamConnect(ip, port) {
    // return "steam://connect/", ip, ":", port
    //return "steam://rungameid/629760/open/" + ip + ":" + port
    return "steam://rungameid/629760"
}

function getUrlAccountid(accountId) {
    // return "steam://connect/", ip, ":", port
    //return "steam://rungameid/629760/open/" + ip + ":" + port
    return "/mhsw/" + accountId
}

const ServerCard = (props) => {
    // console.log(props)
    return (

        <CardGroup>
            {props.servers.map((server, index) =>
                <Col key={index} md="6" lg="6" sm="12">
                    <Card className={getCardBG(server.Tags.MapName)} color="dark" outline
                        style={{
                            minHeight: '265px',
                            maxHeight: '265px'
                        }}
                    >
                        <CardBody>
                            <CardTitle tag="h5" style={{ color: "#000", WebkitTextStroke: '1px yellow', backgroundColor: "rgba(0, 0, 0, 0)", opacity: '1' }}>
                                {getFlag(server.Tags.Location)} {server.Tags.ServerName}
                            </CardTitle>
                            <CardSubtitle
                                className="mb-2 text-muted"
                                tag="h6"
                            >
                                {server.ServerHostname}:{server.ServerPort}
                            </CardSubtitle>
                            <CardText>
                                Players: {server.Tags.Players && server.Tags.Players.length !== 0 ? server.Tags.Players.split(',').length : 0} / {server.Tags.MaxPlayers}<br />
                                Map: {server.Tags.MapName}<br />
                                Mode: {server.Tags.GameMode}<br />
                                Password: {server.Tags.IsPasswordProtected === 'true' ? <BsToggle2On /> : <BsToggle2Off />}<br />
                                Start Mordhau: <a href={getSteamConnect(server.ServerHostname, server.ServerPort)}><BsFillPlayBtnFill size="30px" /></a><br />
                                <a href={getUrlAccountid(server.Tags.AccountID)} target="_self">Server details</a>
                            </CardText>

                        </CardBody>
                    </Card>
                </Col>
            )
            }
        </CardGroup >
    );
}

export default ServerCard;