import React from 'react';
import { Link } from 'react-router-dom';

export default function NavLinks() {
    return (
        <>
            [<Link to="/">Home</Link>]
            Community [<Link to="/sws">Long</Link>][<Link to="/swsc">Compact</Link>]
            [<Link to="/starwars">Spec's server</Link>]
        </>
    )
}
