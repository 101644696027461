import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import "../App.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import { SiLinux } from 'react-icons/si';
import { SiWindows } from 'react-icons/si';

const override = css`
  text-align: right;
  display: block;
`;

// function getMap(map) {
//   switch (map) {
//     case 'JediTemple': return 'jedicard';
//     case 'Hoth': return 'HothBG';
//     default: return "SenateBG"
//   }
// }

export default function ServerConfig(props) {
  //console.log(props)
  return (
    <>
      <Container>
        <Row>
          <Col xs="3" className="d-none d-md-block">name</Col>
          <Col xs="7">{props.config.Info.Environment === "l" ? <SiLinux /> : <SiWindows />} {props.config.Info.Name}</Col>
          <Col xs="2">
            <ScaleLoader
              css={override}
              height={14}
              width={4}
              radius={3}
              margin={1}
              color={"#FF0000"}
              loading={props.loadinfo}
            />
          </Col>
        </Row>

        <Row>
          <Col xs="3">players</Col>
          <Col xs="auto">{props.config.Info.Keywords.split(',')[1].split(':')[1]} / {props.config.Info.MaxPlayers} </Col>
          <Col></Col>
        </Row>

        <Row>
          <Col xs="3">map</Col>
          <Col xs="auto">{props.config.Info.Map}</Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  )
}