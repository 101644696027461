import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import "./App.css";
import ReactPlayer from 'react-player'
import NavLinks from './components/NavLinks';

class VideoApp extends Component {
    render() {
        return (
            <div className="JediBG">
                <Container className="font-starjedi">
                    <NavLinks />
                    <br />
                    <h2>Footage</h2>
                    <p>Me at my most masochistic.</p>
                    <Row>
                        <Col className="videoname d-flex justify-content-center align-items-center" md="2">Video1</Col>
                        <Col md="10">
                            <div className='player-wrapper'>
                                <ReactPlayer
                                    className='react-player'
                                    url={[
                                        { src: 'https://projects.vinite.eu/mordhau/swvideo1.webm', type: 'video/webm' },
                                        { src: 'https://projects.vinite.eu/mordhau/swvideo1.mp4', type: 'video/mp4' }
                                    ]}
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div >
        );
    }
}
export default VideoApp;