import React, { useState, useEffect } from 'react';
import PlayerList from './components/PlayerList';
import ServerConfig from './components/ServerConfig';
import ModList from './components/ModList';
import { Container } from 'reactstrap';
import NavLinks from './components/NavLinks';
import "./App.css";

function displayError(error) {
  return (
    <div className="font-starjedi">
      <h1>This is not the page you are looking for. </h1><h4>{error}</h4>
    </div>
  )
};

function displayNoSupport() {
  return (
    <div className="DefaultBG">
      <div className="notsupported">
        <h3>Sorry, not supported game type.</h3>
      </div>
    </div>
  )
};

function Server(props) {
  //console.log(window.location.pathname.split('/')[2])
  // const serverip = window.location.pathname.split('/')[2]
  // const pathname = window.location.pathname
  // const gametype=props.raw.folder
  // console.log(props)
  //const [serverconfig, setserverconfig] = useState({ "state": "loading" });
  const [serverconfig, setserverconfig] = useState(0);
  const [loadinganim, setloadinganim] = useState({ "loading": true });

  const fetchData = async () => {
    setloadinganim({ "loading": true })
    fetch('https://insapi.vinite.eu/api/mordhau')
      //fetch('http://localhost:4848/api/mordhau')
      .then(response => response.json())
      .then(resultData => {
        setserverconfig(resultData);
        setloadinganim({ "loading": false })
      })
      .catch(error => setserverconfig({ "state": "error", "msg": "Server info could not be retrieved." }))
  }

  // http://localhost:8010/proxy/api/104.243.35.239:27102
  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 15000);
    return () => clearInterval(interval);
  }, [])

  // function getMap(map) {
  //   switch (map) {
  //     case 'JediTemple': return 'JediTempleBG';
  //     case 'Hoth': return 'HothBG';
  //     default: return "SenateBG"
  //   }
  // }
  // console.log(loadinganim.loading)
  // console.log(serverconfig)
  return (
    <div className="JediBG">
      <Container className="font-starjedi">
        <NavLinks />
        {typeof serverconfig.Info !== 'undefined' ?
          <>
            <ServerConfig config={serverconfig} loadinfo={loadinganim.loading} />
            <PlayerList config={serverconfig} />
            {typeof serverconfig.Mods.data !== 'undefined' &&
              <ModList config={serverconfig.Mods} />
            }

            <Container className="brandtext">
              The JVS community MOD contributers (2022)
            </Container>
          </>
          : serverconfig.state === "error" ? displayError(serverconfig.msg)
            : typeof serverconfig.Info !== 'undefined' ? displayNoSupport()
              : <> <br />Loading... </>}
      </Container>
    </div>
  );
}


export default Server;
